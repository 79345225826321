@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("Nunito-Regular.a3bdb4bc.ttf") format("truetype");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("Nunito-SemiBold.05cffc08.ttf") format("truetype");
}

@font-face {
  font-family: Nunito;
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url("Nunito-Bold.7be31947.ttf") format("truetype");
}

@font-face {
  font-family: Mont;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("Mont-Heavy.4a246a95.otf") format("opentype");
}

.material-icons, .material-icons-outlined, .material-icons-round, .material-icons-sharp, .material-icons-two-tone {
  vertical-align: middle;
  font-size: inherit !important;
  line-height: inherit !important;
}
/*# sourceMappingURL=index.777e1fab.css.map */
