@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Nunito-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/Nunito-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('../fonts/Nunito-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Mont';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/Mont-Heavy.otf') format('opentype');
}

.material-icons,
.material-icons-outlined,
.material-icons-round,
.material-icons-sharp,
.material-icons-two-tone {
  font-size: inherit !important;
  line-height: inherit !important;
  vertical-align: middle;
}

